<template>
  <div class="row py-5">
    <div class="wrapper">
      <h4>Not Allowed.</h4>
      <p>Please verify your email or
      <button class="btn btn-link" @click="sendEmail">re-send verification email</button>.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerifyEmail',
  methods: {
    sendEmail() {
      this.$store.dispatch('auth/resendVerificationEmail').then(() => {
        this.$toast.success('Verication Email has been sent');
      }).catch(() => {
        this.$toast.success('We cannot send Verication Email at this time. Try again later.');
      });
    },
  },
};

</script>

<style lang="stylus" scoped>

.wrapper
  margin 0 auto
  .btn-link
    padding 0
    vertical-align baseline

</style>
